.avatar-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .tbl-avatar-sm {
    overflow: hidden;
    width: 35px;
    height: 35px;
    border: 1px solid #cccc;
    border-radius: 50px;
    padding: 3px;
    margin-right: 5px;
    img {
      width: 100%;
    }
  }
}
.tbl-action {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  button {
    background: none !important;
    -moz-appearance: none;
    border-style: none;
    padding: 0;
    text-align: start;
    height: auto;
    color: $crl-2;
  }
  a,
  button {
    float: right;
    margin-left: 5px;
    cursor: pointer;

    &.tbd-view {
      &:before {
        content: "\f065";
      }
    }
    &.tbd-edit {
      &:before {
        content: "\f040";
      }
    }
    &.tbd-delete {
      &:before {
        content: "\f1f8";
      }
    }
    &.tbd-cash {
      &:before {
        content: "\f09d";
      }
    }
    &.tbd-order {
      &:before {
        content: "\f0fe";
      }
    }
    &.tbd-whatsapp {
      &:before {
        content: "\f232";
        color: #1e7c14;
      }
    }
    &.tbd-loc {
      &:before {
        content: "\f041";
      }
      &.active {
        color: #1d67b2;
      }
    }
    &.tbd-gallery {
      &:before {
        content: "\f03e";
      }
      &.active {
        color: #1d67b2;
      }
    }
    &.tbd-audio {
      &:before {
        content: "\f1c7";
      }
      &.active {
        color: #1d67b2;
      }
    }

    &:before {
      font-family: "FontAwesome";
      width: 30px;
      height: 30px;
      display: flex;
      border: 1px solid #a9a7bd;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
    }
  }
}
.user-confirm {
  &:before {
    content: url(../../../img/approve-icon.png);
    margin-right: 8px;
    line-height: 10px;
  }
}
.user-pending {
  &:before {
    content: url(../../../img/pending-icon.png);
    margin-right: 8px;
    line-height: 10px;
  }
}
.user-suspend {
  &:before {
    content: url(../../../img/suspend-icon.png);
    margin-right: 8px;
    line-height: 10px;
  }
}
// Filter ecstion
.c-filter {
  margin-bottom: 10px;
  input,
  select {
    width: auto;
    display: inline-block;
  }
  .c-search,
  .c-sort {
    label {
      font-size: 14px;
    }
  }
}
.carousel-control-next {
  z-index: 1000;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: #000;
  background-size: 25px;
}
.carousel-control-next,
.carousel-control-prev {
  top: -84px;
}
.auction-details-slick {
  padding-bottom: 0;
  /* max-height: 500px; */
}
.auction-details-slick > .slick-list {
  max-height: 350px;
}
.auction-details-slick .slick-slide > div {
  margin-right: 0;
}
.auction-details-slick .slick-slide img {
  height: 343.233px;
  aspect-ratio: 1;
}
.cursor-pointer {
  cursor: pointer;
}

.carousel-indicators {
  bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
  position: static;
  display: inline;
}
.carousel-indicators button {
  width: 23.9% !important;
  margin: 0px 4px 0px 0px;
}
.carousel-indicators button:last-child {
  margin-right: 0px;
}
.carousel-indicators [data-bs-target] {
  text-indent: 0px;
}
.carousel-indicators button {
  width: 23% !important;
}
.carousel-indicators {
  display: flex !important;
  justify-content: center;
  overflow-x: scroll;
  padding-left: 50px;
}
.carousel-indicators li {
  margin: 0;
  height: 50px !important;
  width: 50px !important;
  margin-left: 20px;
  margin-right: 20px;
}

.object-contain {
  object-fit: contain;
}
